import { HubConnectionState } from '@microsoft/signalr';
import { DomainEventName } from '../services/command-request.service';
import { HttpResponse } from '@angular/common/http';
import { UserProfile } from '@rockwell-automation-inc/service';
import { IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { Action } from '@ngrx/store';
import { AppId } from '@rockwell-automation-inc/common-utils';

export interface AppState {
  controlPage: PageControl;
  signalR: SignalRData;
  snackBarData: SnackBarMessage;
  user: UserState;
  trials: ActiveCampaign;
  agGrid: AgGridState;
  tenant: TenantState;
  sessionManagement: SessionManagement;
  featureFlags: FetaureFlags;
}

export interface FetaureFlags {
  flags?: Record<string, FlagValue>;
}

export interface AgGridState {
  refreshAgGrid?: boolean;
}

export interface PageControl {
  isLoading: boolean;
  loggedInUserProfile?: UserProfile;
  catalogInfo?: ICatalogInfo[];
}

export interface IEntitlemetState {
  entitlements: any[];
  total?: number;
  currentPage?: number;
}

export type SortModel = {
  colId: string;
  sort: 'asc' | 'desc';
};

export type FilterType =
  | 'greaterThanOrEqual'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'lessThan'
  | 'equals'
  | 'notEqual'
  | 'inRange'
  | 'contains';

export type FilterContext = {
  filterType: 'set' | 'number' | 'text' | 'date';
  type?: FilterType;
  filter?: any;
  dateTo?: Date;
  dateFrom?: Date;
  values?: string[];
  filterTo?: any;
};

export type FilterModel = {
  [key: string]: FilterContext;
};
export interface SortFilterContext {
  sortModel: SortModel[];
  filterModel: FilterModel;
}

export interface EntitlementContext {
  perPage: number;
  pageNum: number;
  contractNo?: string;
  email?: string;
  sortFilterOpts?: SortFilterContext;
  tenantId?: string;
}

export interface TrialsReservations {
  perPage: number;
  pageNum: number;
  sortFilterOpts?: SortFilterContext;
}

export interface PaginatedResponse<T> {
  currentPage: number;
  records: T[];
  total: number;
}

export type AgFilterModel = {
  [key: string]: AgFilterContext;
};
export type AgFilterContext = {
  filterType: 'set' | 'number' | 'text' | 'date';
  type?: FilterType;
  filter?: any;
  filterTo?: any;
  dateTo?: string;
  dateFrom?: string;
  values?: (string | null)[];
};

export interface ICatalogInfo {
  catalogCode: string;
  name: string;
  description: string;
  attributes: any[];
  serviceKind: string;
  productNumber?: string;
}

export enum EntitlementBadge {
  addon = 'Add-On',
  platform = 'Platform',
  additive = 'Additive',
  trial = 'Trial',
  utilityToken = 'Universal Credits',
}

export enum EntitylementTypes {
  addOns = 'addon',
  trial = 'trial',
  platform = 'platform',
  additive = 'additive',
  utilityToken = 'utilityToken',
}

export enum PreviewAppId {
  Fiix = 'Fiix',
  EPlan = 'EPlan',
  Plex = 'Plex',
  CDS = 'CDS',
  FactoryTalkMotionAnalyzer = 'FactoryTalkMotionAnalyzer',
  Advisor = 'Advisor',
}

export type UniversalCredits = 'UniversalCredits';

export function isPreviewApp(s: AppId | PreviewAppId | string): s is PreviewAppId {
  return s in PreviewAppId;
}

export type ApplicationCard = {
  appName: string;
  appId: AppId;
  isDefaultTrialEnabled?: boolean;
};

export interface TenantService extends Service {
  canAccess: boolean;
  role: string;
  provisioned: boolean;
}

export enum MessageIcons {
  Info = 'info-blue.svg',
  Success = 'check-circle-green.svg',
  Error = 'client-error-red.svg',
  Warning = 'warning-yellow.svg',
  LicenseScroll = 'license-scroll.svg',
}

export enum Role {
  Navigator = 'Navigator',
  Reader = 'Reader',
  Contributor = 'Contributor',
  Admin = 'Admin',
  Owner = 'Owner',
  BillingAdmin = 'BillingAdmin',
}

export interface InviteAdmin {
  payloadBody: {
    userEmail: string;
    tenantId: string;
    contextData: any;
  };
  message: string;
}

export interface ErrorContext {
  url: string;
  message: string;
  correlationId?: string;
}
export interface ErrorMessageMap {
  [httpStatus: number]: string;
}
export interface IContext {
  correlationId: string;
  causationId: string;
  userId: string;
  tenantId: string;
}

export type AnyObject = Record<string, unknown>;

export interface IDomainMessage {
  type: DomainEventName | string;
  data: AnyObject;
  context: IContext;
}

export function isDomainMessage(val: IDomainMessage | any): val is IDomainMessage {
  return (val as unknown as IDomainMessage).context !== undefined;
}

export interface IDomainUpdateResponse {
  response: HttpResponse<object>;
  message: IDomainMessage;
}
export interface SignalRData {
  connectionState?: HubConnectionState;
  domainMessage?: IDomainMessage;
}

export interface SessionManagement {
  isUserActive?: boolean;
  userActivityRecorded: boolean;
  sessionTimedOut: boolean;
  extendSession: boolean;
  logoutSession: boolean;
  lastUserActivityRecordedAt: number;
}

export enum ResourceType {
  Tenant = 'Tenant',
  Service = 'Service',
}

export type ResourceTypeString = keyof typeof ResourceType;
export interface Resource {
  resourceId: string;
  resourceName: string;
  resourceType: ResourceTypeString;
}

export type Invitation = Resource & {
  id: string;
  toEmail: string;
  acceptedByUser: string;
  acceptedByUserName: string;
  createdBy: string;
  createdByUserName: string;
  sentDate: Date;
  tenantId: string;
  role: Role;
  status: string;
  expiry: Date;
  buttonsConfig?: any;
};

export interface SnackBarData {
  message: string;
  type: string;
  title?: string;
  duration?: number;
  position?: string;
  customBtn?: customBtn;
}

export interface customBtn {
  label: string;
  navigateTo: string;
}

export interface SnackBarMessage {
  data?: SnackBarData;
}

export interface ErrorContext {
  url: string;
  message: string;
  correlationId?: string;
}

export interface Version {
  apiVersion: string;
  portalVersion: string;
  notificationsVersion: string;
}

export interface ActiveCampaign {
  id: string;
  partitionId: string;
  viewType: string;
  status: string;
  reatedOn: string;
  name: string;
  trialDurationInDays: number;
  trialRedemptionLimitInDays: number;
  createdByUserId: string;
  createdByUser: string;
  endedByUserId: string;
  endedByUser: string;
  remainingCredits: number;
  perTrialCredits: number;
  campaignCredits: number;
}

export interface CampaignDetails {
  bundleId: string;
  createdByUser: string;
  createdByUserId: string;
  createdOn: Date;
  description: string;
  endedByUser: string;
  endedByUserId: string;
  endedOn: Date;
  id: string;
  name: string;
  partitionId: string;
  pausedByUser: string;
  pausedByUserId: string;
  pausedOn: Date;
  redeemedTrials: number;
  reservedTrials: number;
  status: TrialsCampaignStatus;
  trialDurationInDays: string;
  trialRedemptionLimitInDays: number;
  viewType: string;
  perTrialCredits: number;
  credits: number;
  trialsQuantity: number;
  remainingTrialsQuantity: number;
  availableReservations?: string;
}

export interface ReserveTrial {
  campaignId: string;
  customerInfo: {
    name: string;
    email: string;
    company: string;
  };
}

export interface CancelTrial {
  campaignId: string;
  reservationId: string;
}

export interface Trials {
  campaignDetails: CampaignDetails;
  trialBundles: TrialBundle[];
}

export interface UserState {
  userId: string;
  name: string;
  location: string;
  email: string;
  company: string;
  secondaryEmails: string[];
  idpConnectionName: string;
  organizations: Organizations[];
}

export interface TenantState {
  tenantId: string;
  tenantUtilityTokens?: TenantUtilityTokens;
  tenantUsers: TenantUser[];
  selectedTenantUsers: PaginatedResponse<{}>;
  tenantInfo: ITenantDetail;
}

export interface Organizations {
  name: string;
  id: string;
}

export interface ServiceResolutionData {
  resolutionData: {
    error: string;
  };
  resolutionType: {
    Case: ResolutionType;
  };
}

export type ResolutionType = 'Completed' | 'Failed' | 'Cancelled';

export class ServiceResolutionError extends Error {
  constructor(public errorCode: string) {
    super();
    this.name = 'ServiceResolutionError';
  }
}

export enum RASymbols {
  MINUS = '—',
}

export enum TrialsStatus {
  active = 'Active',
  cancelled = 'Cancelled',
  linkexpired = 'Link Expired',
  expired = 'Trial Expired',
  redeemed = 'Redeemed',
  unredeemed = 'Unredeemed',
  provisioning = 'Provisioning',
  ended = 'Ended',
  paused = 'Paused',
}

export enum TrialsCampaignStatus {
  active = 'Active',
  ended = 'Ended',
  paused = 'Paused',
}

export interface ChipInfo {
  color: any;
  text: string;
}

export interface Attributes {
  combineType: string;
}
export interface Entitlement {
  id: string;
  catalogNumber: string;
  contractNumber: string;
  status: string;
  name: string;
  description: string;
  serviceKind: AppId | '' | UniversalCredits;
  effectiveDate: Date;
  expiryDate: Date;
  primaryEmail: string;
  consumed?: number;
  userId: string;
  tenantId: string;
  serviceId: string;
  fromHybris: boolean;
  quantity: number;
  isSystemGenerated: boolean;
  attributes: Attributes;
  available: number;
  timestamp: string;
  allocated: number;
}
export interface TokenEntitlement extends Entitlement {
  entitlementId: string;
}

export interface AGGridEntitlement extends Entitlement {
  entitlementHeaders: AgGridEntitlementColumnsFormat;
  productFamily: string;
  entitlementType: string;
}
export interface EntitlementData {
  entitlement: Entitlement;
  app: ApplicationCard;
  serviceId?: string;
  tenantId: string;
  userId: string;
  continue?: boolean;
}
export interface TenantUtilityTokens {
  id: string;
  tokenBalance: number;
  tokenExpiration?: Date;
  tokenEntitlements: Entitlement[];
  disabledTokenEntitlements: Entitlement[];
}

export interface TenantUtilityTokensResponse {
  id: string;
  tokenBalance: number;
  tokenExpiration?: Date;
  tokenEntitlements: TokenEntitlement[];
  disabledTokenEntitlements: TokenEntitlement[];
}
export interface AgGridEntitlementColumnsFormat {
  header: string;
  subheader: string;
}

export interface OwnerInfo {
  id: string;
  email: string;
  name: string;
}
export interface Service {
  serviceId: string;
  kind: AppId;
  ownedBy: OwnerInfo;
  entitlements: Entitlement[];
}

export interface AgGridService {
  serviceId: string;
  serviceName: string;
  ownerName: string;
  ownerEmail: string;
  userId: string;
  status: string;
}

export interface DisabledService extends Service {
  disabledReason: string;
}

export interface PendingService {
  serviceId: string;
  kind: AppId;
  entitlement: Entitlement | null;
}

export type EntitlmentService = DisabledService | Service;

export interface ITenantDetail {
  id: string;
  name: string;
  ownerName: string;
  owner: string;
  location: string;
  emailDomain: string;
  visibility: string;
  description: string;
  services: Service[];
  disabledServices: DisabledService[];
  pendingServices: PendingService[];
  entitlements: Entitlement[];
}
export interface ResetMFA {
  userId: string;
  userEmail: string;
  contextData: {
    approvedBy: string;
    requestedOnTimeStamp: number;
  };
}

export interface TransferOwnership {
  payloadBody: {
    userId: string | undefined;
    resourceId: string;
    tenantId: string;
  };
  message: string;
}

export interface ConfirmModalConfig {
  config: IDialogConfig;
  type: NotificationType;
  action: Action;
}

export enum EntitlementStatus {
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum ServiceStatus {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
}

export type TenantId = {
  tenantId: string;
};

export type Pagination = {
  perPage: number;
  pageNum: number;
};

export type PaginatedRequest = {
  tenantId: TenantId;
  pagination?: Pagination;
  sortFilterContext?: SortFilterContext;
};

export interface TenantUser {
  firstName: string;
  lastName: string;
  userEmail: string;
  userId: string;
  userName: string;
}

export type CreateCampaign = {
  name: string;
  description: string;
  trialsQuantity: number;
  trialDurationInDays: number;
  trialRedemptionLimitInDays: number;
  perTrialCredits: number;
  bundleId: string;
};

export enum BannerType {
  SessionTimeout = 'SessionTimeout',
}

export type TenantUserService = {
  ownerEmail: string;
  ownerName: string;
  serviceId: string;
  serviceName: string;
  userId: string;
};

export type FlagValue = boolean | string | number | object | undefined;

export type FeatureFlag = 'enable_language_switcher' | 'disable_mfa';

export interface FeatureFlagUpdate {
  flag: FeatureFlag;
  value: FlagValue;
}

export type HarnessIdentifier = UserId | 'common-admin-portal';

export interface FeatureFlagTarget {
  identifier: HarnessIdentifier;
  attributes: {
    // choice type for target
    Type: 'User' | 'Application';
    Domain?: string;
  };
}

export type UserId = string & { isGuid: true };

export interface TrialBundle {
  id: string;
  //This has been added for UI filtering on name and description
  nameAndDescription: [string, string]; 
  //This has been added for UI filtering on credits and product catalogs
  creditsAndProductCatalogs: string[];
  name: string;
  description: string;
  credits: string | null;
  products: {
    catalog: string;
    quantity: number;
  }[];
}
