import { Action, createReducer, on } from '@ngrx/store';
import { setCampaignDetails, setTrialBundles } from './trials.action';
import { Trials, TrialsCampaignStatus } from '@ra-state';

export const initialTrialsState: Trials = {
  campaignDetails: {
    bundleId: '',
    createdByUser: '',
    createdByUserId: '',
    createdOn: '' as unknown as Date,
    description: '',
    endedByUser: '',
    endedByUserId: '',
    endedOn: '' as unknown as Date,
    id: '',
    name: '',
    partitionId: '',
    pausedByUser: '',
    pausedByUserId: '',
    pausedOn: '' as unknown as Date,
    redeemedTrials: 0,
    reservedTrials: 0,
    status: '' as TrialsCampaignStatus,
    trialDurationInDays: '',
    trialRedemptionLimitInDays: 0,
    viewType: '',
    perTrialCredits: 0,
    credits: 0,
    trialsQuantity: 0,
    remainingTrialsQuantity: 0,
  },
  trialBundles: [],
};

const trialsData = createReducer(
  initialTrialsState,
  on(
    setCampaignDetails,
    (state: Trials, { payload }): Trials => ({
      ...state,
      campaignDetails: payload,
    }),
  ),
  on(
    setTrialBundles,
    (state: Trials, { payload }): Trials => ({
      ...state,
      trialBundles: payload,
    }),
  ),
);

export function trialsReducer(state: Trials, action: Action): any {
  return trialsData(state, action);
}
